import React from "react"
import { Target } from "../../../utils"
import FaqCard from "./FaqCard"

const Faq = ({ target }: { target: Target }) => {
  const data = [
    target !== "do"
      ? {
          question: "児童発達支援とは?",
          answer:
            "未就学児対象で、身体的・精神的機能の適正な発達を促し、日常生活および社会生活を円滑に営めるようにする、一人ひとりの特性に応じた福祉的・心理的・教育的および医療的なサポートです。",
        }
      : {
          question: "",
          answer: "",
        },
    {
      question: "放課後等デイサービスとは？",
      answer:
        "就学している児童の放課後や長期休暇中において、日常生活における基本的な動作の習得や集団生活への適応訓練等を行なうとともに放課後の居場所づくりを行ないます。",
    },
    {
      question: "利用対象となるお子様は？",
      answer:
        target !== "do"
          ? "小学校に入学する前の身体の障害のある児童や、知的障害・発達障害を含む精神障害をのあるお子さまが対象です。また各事業所の所在市町村に在住の小・中・高生（特別支援学校、特別支援学級、通常学級）で放課後等デイサービス（児童福祉法）の支給決定を受けている方となります。"
          : "各事業所の所在市町村に在住の小・中・高生（特別支援学校、特別支援学級、通常学級）で放課後等デイサービス（児童福祉法）の支給決定を受けている方となります。",
    },
    {
      question: "送迎はしてもらえますか？",
      answer:
        "送迎は行なっております。直接ご説明させていただきますので、ご見学時にご相談ください。",
    },
    {
      question: "すぐに利用できますか？",
      answer:
        "サービスの利用には、現在住んでいる市区役所にて受給者証（障害福祉サービス受給者証）の取得が必要となります。まずはお住いの市町村にお問い合わせください。",
    },
    {
      question: "サービスを利用する料金はいくらかかりますか？",
      answer:
        "法定利用料金が児童福祉法により定められています。お支払いただく料金は、利用料の1割負担となります。また、複数の事業所を併用されても、世帯により定められている上限額を越えることはありません。3歳から5歳までのお子さまは無償化の対象になっています。",
    },
    {
      question: "利用料金以外に費用はかかりますか？",
      answer:
        "1回ご利用ごとにおやつ代（５０円）が必要です。また、外出の際の交通費、創作材料費などがかかる場合がありますが、そのときは事前に連絡させていただきます。",
    },
    {
      question: "当日利用は可能ですか？",
      answer:
        "１日の利用定員が決まっていますので、スタッフにご相談ください。空き状況やスタッフシフト等の関係でお受けできない場合もあります。",
    },
    {
      question: "行事はありますか？",
      answer:
        "夏祭り、クリスマス会、餅つき大会など、四季折々を大切にし楽しい行事を企画しております。",
    },
    {
      question: "当日予約はできますか？",
      answer:
        "利用定員がありますので、その時の空き状況や、スタッフのシフト等の関係で、急なご利用のお申し込みは、お受けできない場合があります。電話で直接お問い合わせください。",
    },
    {
      question: "夏休みなど、学校が長いお休みに入った時はどうなりますか？",
      answer:
        "利用可能日・利用可能時間内であれば、学校の長期休暇中もご利用いただけます。但し、利用定員（１日１０名）がありますのでお断りする場合もあります。",
    },
    {
      question: "どのように過ごすのですか？",
      answer:
        "それぞれ支援内容が異なりますので、一人ひとりに合わせた取り組みを行い、他児との関わり合いも大切にサポートさせていただきます。",
    },
    {
      question: "どのように予約しますか？",
      answer: "お電話での受け付けをいたします。",
    },
    {
      question: "医師の診断がなくても利用できますか？",
      answer:
        "可能です。診断がある場合は、必ず事業所にお伝えいただきますようお願いいたします。確定診断のないお子さまもご利用されています。",
    },
    {
      question: "子どもがプログラムについていけるか心配です。",
      answer:
        "スタッフが個別に対応させて頂きますので、ご安心ください。また、集団プログラムだけでなく、個々の発達や特性に合わせた個別のプログラムも用意しておりますので、お気軽にご相談ください。",
    },
    {
      question: "身体に障がいがあるのですが利用可能でしょうか？",
      answer:
        "原則は知的の障害のある、もしくはその疑いのあるお子さまが対象です。トイレをはじめ、室内も車いすでの対応ができかねる状態になっております。希望がある際はお気軽にご相談ください。",
    },
    {
      question: "食事はできますか？",
      answer:
        "学校休業日（日曜・長期休暇など）は、お昼休みがあるため、お弁当をお持ちいただくか、近くのコンビニ等にスタッフと買いにいくこともできます。詳細は、各事業所にお問合せください。",
    },
  ].filter(({ question }) => question)

  return (
    <div className="mt-12 md:mt-20">
      {data.map(item => {
        return <FaqCard {...item} target={target} key={item.question} />
      })}
    </div>
  )
}

export default Faq
