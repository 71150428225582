import React, { useState } from "react"
import { getTextColor, Target } from "../../../utils"

type Props = {
  question: string
  answer: string
  target: Target
}

const FaqCard: React.VFC<Props> = ({ question, answer, target }) => {
  const [isShow, setIsShow] = useState(false)
  const onClick = () => {
    setIsShow(!isShow)
  }

  return (
    <div className="p-4 mb-6 border md:p-5 md:mb-10 rounded-3xl" key={question}>
      <button className="flex items-center w-full" onClick={onClick}>
        <div className={`text-2xl ${getTextColor(target)}`}>Q</div>
        <div className="ml-8">{question}</div>
        <div className={`ml-auto text-2xl ${getTextColor(target)}`}>
          {isShow ? "＋" : "ー"}
        </div>
      </button>
      {isShow && (
        <div>
          <div className="mt-8 border-dash"></div>
          <div className="flex mt-8">
            <div className={`text-2xl ${getTextColor(target)}`}>A</div>
            <div className="ml-8">{answer}</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default FaqCard
